












































































































































































import { Component, Vue, Prop, Provide } from 'vue-property-decorator'
import { FormSchema, FormField, FieldType } from '@/models'
import Loading from '@/components/Loading.vue'
import Field from '@/components/fields/Field.vue'
import _pickBy from 'lodash/pickBy'
import _mapValues from 'lodash/mapValues'
import FuzzySearch from 'fuzzy-search'
import { ValueType } from '@/models/Value'

@Component({
  components: {
    Field,
    Loading
  }
})
export default class OptionFields extends Vue {
  /** Form Schema */
  @Prop({ type: Object, required: true }) schema!: FormSchema
  /** Disabled state */
  @Prop({ type: Boolean }) disabled!: boolean
  /** Environment Variables */
  @Prop({ type: Object, default: () => ({}) }) environmentVariables!: Record<
    string,
    any
  >

  /** Values */
  @Prop({ type: Object, default: () => ({}) }) value!: Record<string, any>
  /** External Validation Errors */
  @Prop({ type: Object, default: () => ({}) }) validationErrors!: Record<
    string,
    string[]
  >

  @Prop({
    type: Array,
    default: () => ['fixed', 'parameter', 'parameterWithFallback']
  })
  allowedValueTypes!: string[]

  @Prop({ type: Array, default: () => [] }) hideOptions!: string[]

  @Provide() fieldValues() {
    return _mapValues(
      _pickBy(this.value, (v) => v.type === 'fixed'),
      (v) => v.fixed?.value || null
    )
  }

  /** Field Search Query */
  searchQuery = ''

  /** Is the form valid? */
  valid = true

  get valueTypes() {
    return [
      {
        label: 'Sin Valor',
        icon: 'radio_button_unchecked',
        value: 'none',
        color: 'blue-grey'
      },
      { label: 'Valor Fijo', icon: 'build', value: 'fixed', color: 'blue' },
      {
        label: 'Parámetro',
        icon: 'settings_ethernet',
        value: 'parameter',
        color: 'orange'
      },
      {
        label: 'Parámetro con valor por defecto',
        icon: 'settings_ethernet',
        value: 'parameterWithFallback',
        color: 'pink'
      },
      {
        label: 'Indicador',
        icon: 'subtitles',
        value: 'indicator',
        color: 'cyan'
      },
      {
        label: 'Editable',
        icon: 'keyboard',
        value: 'editable',
        color: 'brown'
      },
      {
        label: 'Variable de Entorno',
        icon: 'settings',
        value: 'env',
        color: 'green'
      },
      {
        label: 'Variable de Usuario',
        icon: 'people',
        value: 'user',
        color: 'red'
      }
    ].filter(
      (t) => t.value === 'none' || this.allowedValueTypes.includes(t.value)
    )
  }

  setValueType(fieldName: string, valueType: string, forceValue: any) {
    const currentValue = this.value[fieldName] || { type: 'none' }
    const newValue = {
      type: valueType
    } as Record<string, any>

    if (newValue.type === currentValue.type) {
      return
    } else if (forceValue) {
      if (newValue.type === 'parameter') newValue.parameterName = forceValue
      if (newValue.type === 'parameterWithFallback') newValue.fixed = forceValue
      if (newValue.type === 'fixed') newValue.fixed = forceValue
    } else if (currentValue.type === 'fixed') {
      if (newValue.type === 'parameter')
        newValue.parameterName = currentValue.fixed.value
      if (newValue.type === 'parameterWithFallback')
        newValue.fixed = currentValue.fixed
    } else if (currentValue.type === 'parameter') {
      if (newValue.type === 'fixed')
        newValue.fixed = { value: currentValue.parameterName }
      if (newValue.type === 'parameterWithFallback')
        newValue.parameterName = currentValue.parameterName
    } else if (currentValue.type === 'parameterWithFallback') {
      if (newValue.type === 'parameter')
        newValue.parameterName = currentValue.parameterName
      if (newValue.type === 'fixed') newValue.fixed = currentValue.fixed
    }

    this.$emit('input', {
      ...this.value,
      [fieldName]: valueType === 'none' ? {} : newValue
    })
  }

  getValueType(fieldName: string) {
    return this.valueTypes.find(
      (vt) =>
        vt.value ===
        ((this.value[fieldName] && this.value[fieldName].type) || 'none')
    )
  }

  getFixedValue(fieldName: string) {
    const value = this.value[fieldName]
    const type = this.getValueType(fieldName)
    if (type?.value === 'none') return null
    return value?.fixed?.value || null
  }

  setFixedValue(fieldName: string, value: any) {
    if (
      this.allowedValueTypes.length === 1 &&
      this.getValueType(fieldName)!.value !== this.allowedValueTypes[0]
    ) {
      this.setValueType(fieldName, this.allowedValueTypes[0], value)
      return
    }
    this.$emit('input', {
      ...this.value,
      [fieldName]: {
        type: 'fixed',
        ...this.value[fieldName],
        fixed: { value }
      }
    })
  }

  get visibleFields() {
    // if (!this.searchQuery.trim()) return this.schema

    const searcher = new FuzzySearch(
      Object.keys(this.schema).map((k) => ({ ...this.schema[k], name: k })),
      ['name', 'label', 'type']
    )
    const matching = searcher
      .search(this.searchQuery)
      .filter((i) => !this.hideOptions.includes(i.name))
    const result: Record<string, Partial<FormSchema>> = {}
    if (matching && matching.length) {
      for (const item of matching) {
        // @ts-ignore
        result[item.name] = item
      }
    }
    return result
  }
}
